<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
    >
      <!--begin::Menu-->
      <router-link to="/account/my-account">
      <div
        class="
          btn btn-icon btn-active-light-primary
          position-relative
          w-30px
          h-30px
          w-md-40px
          h-md-40px">
          <span class="svg-icon svg-icon-1">
            <inline-svg src="/media/icons/duotune/communication/com006.svg" />
          </span>
      </div>
      </router-link>
      <!--end::Menu-->
    </div>
    <!--end::User --> 

    <!--begin::SignOut-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <!--begin::Menu-->
      <div
        class="
          btn btn-icon btn-active-light-primary
          position-relative
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
        @click="signOut()"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="/media/icons/duotune/arrows/arr076.svg" />
        </span>
      </div>
      <!--end::Menu-->
    </div>
    <!--end::SignOut-->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  components:{},
  setup() {
    const router = useRouter();
    const store = useStore();
    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    return {
      signOut,
    };
  },
});
</script>
