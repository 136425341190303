const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/graphs/gra001.svg",
        fontIcon: "bi-app-indicator",
        permission: ["Administrator", "Manager", "Staff", "Faculty"]
      },
      {
        heading: "Student",
        route: "/student/student-list",
        svgIcon: "/media/icons/duotune/general/gen049.svg",
        fontIcon: "bi-layers",
        permission: ["Administrator", "Manager", "Staff"]
      },
      {
        sectionTitle: "Academics",
        route: "/academics",
        svgIcon: "/media/icons/duotune/finance/fin001.svg",
        fontIcon: "bi-layers",
        permission: ["Administrator", "Manager", "Staff", "Faculty"],
        sub: [
          {
            heading: "Class",
            route: "/academics/class",
            permission: ["Administrator", "Manager", "Staff"]
          },
          {
            heading: "Attendance",
            route: "/academics/attendance",
            permission: ["Administrator", "Manager", "Staff", "Faculty"]
          },
          {
            heading: "Assessment & Grading",
            route: "/academics/assessments/0",
            permission: ["Administrator", "Manager", "Staff", "Faculty"]
          },
        ],
      },
      {
        sectionTitle: "Management",
        route: "/management",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-layers",
        permission: ["Administrator", "Manager", "Staff"],
        sub: [
          {
            heading: "Admin Report",
            route: "/management/admin-report",
            permission: ["Administrator", "Manager", "Staff"]
          },
          {
            heading: "Student Invoice",
            route: "/management/invoice",
            permission: ["Administrator", "Manager", "Staff"]
          },
          {
            heading: "Faculty / Staff",
            route: "/management/staff",
            permission: ["Administrator", "Manager", "Staff"]
          },
          {
            heading: "Agent",
            route: "/management/agent",
            permission: ["Administrator", "Manager", "Staff"]
          },
          {
            heading: "Calendar",
            route: "/management/calendar",
            permission: ["Administrator", "Manager", "Staff"]
          },
          {
            heading: "School Forms",
            route: "/management/school-forms",
            permission: ["Administrator", "Manager", "Staff"]
          },
        ],
      },
      {
        heading: "Financial",
        route: "/financial",
        svgIcon: "/media/icons/duotune/finance/fin007.svg",
        fontIcon: "bi-layers",
        permission: ["Administrator", "Manager"]
      },
      {
        heading: "User",
        route: "/users",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-layers",
        permission: ["Administrator", "Manager"]
      },
    ],
  },
];

export default DocMenuConfig;
