
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
  name: "topbar",
  components:{},
  setup() {
    const router = useRouter();
    const store = useStore();
    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    return {
      signOut,
    };
  },
});
